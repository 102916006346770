


function checkOneShippingMethod(): void {
    const shippingMethods: NodeListOf<HTMLInputElement> = document.querySelectorAll(".shipping_method");
    if (shippingMethods.length > 0) {
        shippingMethods[0].checked = true;
    }
}

document.addEventListener("DOMContentLoaded", () => {
    checkOneShippingMethod();

});